import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import options from "../components/richtextOptions"
import ReactPlayer from "react-player"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SimpleCard from "../components/simpleCard"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "100px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  titolo: {
    color: "#fe5f55",
    fontSize: "2rem",
    fontWeight: 900,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  periodoTab: {
    color: "#305aed",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 900,
  },
  storia: {
    marginTop: "20px",
    paddingTop: "85px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})(props => (
  <Tabs
    orientation="vertical"
    variant="scrollable"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
))

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: "800",
    transition: theme.transitions.create(
      ["background-color", "color", "opacity"],
      { duration: "500ms" }
    ),
    "&:focus": {
      opacity: 1,
      color: "#fff",
      backgroundColor: "#305aed",
    },
    "&:hover": {
      border: "1px solid #305aed",
    },
  },
}))(props => <Tab disableRipple {...props} />)

const Storia = ({ data }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Layout title="Storia">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  58 anni di STORIA
                </h1>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  In 58 anni le squadre dell'associazione S.S. Basket San Donà
                  hanno giocato in svariati campionati, dalla provincia ai
                  campionati nazionali.
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReactPlayer
                  url="https://player.vimeo.com/video/531284325?autoplay=1&muted=1&background=1&loop=1"
                  width="100%"
                  height="100%"
                  config={{
                    vimeo: {
                      playerOptions: {
                        autoplay: true,
                        loop: true,
                        muted: true,
                        dnt: true,
                        background: true,
                        controls: false,
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.storia}>
          <Container>
            <Grid container spacing={5}>
              <Grid item xs={4} sm={4}>
                <h4 className={classes.periodoTab}>Seleziona il periodo</h4>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Squadre dei periodi"
                  className={classes.tabsMenu}
                >
                  {data.allContentfulPeriodo.nodes.map((periodo, index) => (
                    <StyledTab
                      label={periodo.nome}
                      key={index}
                      {...a11yProps(index)}
                    />
                  ))}
                </StyledTabs>
              </Grid>
              <Grid item xs={8} sm={8}>
                {data.allContentfulPeriodo.nodes.map((periodo, index) => (
                  <TabPanel value={value} index={index} key={index}>
                    <Grid item container key={index}>
                      <Grid item xs={12}>
                        <h1 className={classes.titolo}>
                          Periodo {periodo.nome}
                        </h1>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          {documentToReactComponents(
                            periodo.descrizione.json,
                            options
                          )}
                        </Grid>
                        <Grid item container spacing={5}>
                          <Grid item xs={12}>
                            <h2 className={classes.titolo}>
                              Squadre del periodo
                            </h2>
                          </Grid>
                          {periodo.anni.map((anno, indexAnno) =>
                            anno.squadre.map((squadra, indexSquadra) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                key={indexSquadra}
                              >
                                <SimpleCard
                                  type="squadre"
                                  title={squadra.nome}
                                  slug={"/squadre/" + squadra.slug}
                                  image={squadra.foto}
                                />
                              </Grid>
                            ))
                          )}
                        </Grid>
                        <Grid item container spacing={5}>
                          <Grid item xs={12}>
                            <h2 className={classes.titolo}>
                              Articoli del periodo
                            </h2>
                          </Grid>
                          {periodo.anni.map((anno, indexAnno) => {
                            if (
                              anno.articoli !== null &&
                              anno.articoli !== undefined
                            ) {
                              return anno["articoli"].map(
                                (articolo, indexArticolo) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    key={indexArticolo}
                                  >
                                    <SimpleCard
                                      type="articoli"
                                      title={articolo.titolo}
                                      slug={"/articoli/" + articolo.slug}
                                      image={articolo.foto}
                                    />
                                  </Grid>
                                )
                              )
                            }
                            return <span key={indexAnno}></span>
                          })}
                        </Grid>
                        <Grid item container spacing={5}>
                          <Grid item xs={12}>
                            <h2 className={classes.titolo}>
                              Manifesti del periodo
                            </h2>
                          </Grid>
                          {periodo.anni.map((anno, indexAnno) => {
                            if (
                              anno.manifesti !== null &&
                              anno.manifesti !== undefined
                            ) {
                              return anno.manifesti.map(
                                (manifesto, indexManifesto) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    lg={4}
                                    key={indexManifesto}
                                  >
                                    <SimpleCard
                                      type="manifesti"
                                      title={manifesto.titolo}
                                      slug={"/manifesti/" + manifesto.slug}
                                      image={manifesto.media[0].media}
                                    />
                                  </Grid>
                                )
                              )
                            }
                            return <span key={indexAnno}></span>
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Storia

export const query = graphql`
  {
    allContentfulPeriodo(
      sort: { fields: nome, order: ASC }
      filter: { node_locale: { eq: "it-IT" } }
    ) {
      nodes {
        nome
        descrizione {
          json
        }
        anni {
          squadre {
            nome
            slug
            foto {
              fluid {
                src
              }
            }
          }
          articoli {
            titolo
            slug
            data
            descrizione
            foto {
              fluid {
                src
              }
            }
          }
          manifesti {
            titolo
            slug
            descrizione
            testo {
              json
            }
            media {
              titolo
              descrizione {
                descrizione
              }
              media {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
